const style = {
  // HomePage
  'root::HomePage::PageHeader::PageTitle': {
    titleText: {
      color: '#1C4547',
    },
    titleIcon: {
      color: '#1C454780',
    },
  },
  'root::MedicationPage::PageHeader::PageTitle': {
    titleText: {
      color: '#1C4547',
    },
    titleIcon: {
      color: '#6B1A2CBF',
    },
  },
  'root::LabResultsPage::PageHeader::PageTitle': {
    titleText: {
      color: '#1C4547',
    },
    titleIcon: {
      color: '#336699BE',
    },
  },
  'root::HomePage::MedicationTable::TableHeader': {
    headerText: {
      color: '#1C4547',
    },
    headerIcon: {
      color: '#6B1A2CBF',
    },
  },
  'root::HomePage::LabResultsTable::TableHeader': {
    headerText: {
      color: '#1C4547',
    },
    headerIcon: {
      color: '#336699BE',
    },
  },
  'root::HomePage::HomePagePanels::HomePageMedicationPanel': {
    gradientPrimary: '#B27677',
    gradientSecondary: '#6E1A2B',
  },
  'root::HomePage::HomePagePanels::HomePageLabResultsPanel': {
    gradientPrimary: '#809DC1',
    gradientSecondary: '#336699',
  },
  'root::MobileHomePage::HomePagePanels::HomePageMedicationPanel': {
    gradientPrimary: '#B27677',
    gradientSecondary: '#6E1A2B',
  },
  'root::MobileHomePage::HomePagePanels::HomePageLabResultsPanel': {
    gradientPrimary: '#809DC1',
    gradientSecondary: '#336699',
  },
  'root::HomePage::SideMenu::PoweredBy': {
    poweredByContainer: {
      backgroundColor: '#EAEAEF',
    },
    poweredByContainerCollapsed: {
      backgroundColor: '#EAEAEF',
    },
    poweredByIcon: {
      height: 40,
    },
  },
  'root::MedicationPage::SideMenu::PoweredBy': {
    poweredByContainer: {
      backgroundColor: '#EAEAEF',
    },
    poweredByContainerCollapsed: {
      backgroundColor: '#EAEAEF',
    },
    poweredByIcon: {
      height: 40,
    },
  },
  'root::LabResultsPage::SideMenu::PoweredBy': {
    poweredByContainer: {
      backgroundColor: '#EAEAEF',
    },
    poweredByContainerCollapsed: {
      backgroundColor: '#EAEAEF',
    },
    poweredByIcon: {
      height: 40,
    },
  },
  'root::HomePage::MedicationTable': {
    tableContainer: {
      borderWidth: 0,
      height: '48vh',
      marginTop: 20,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: '#F4F4F8',
    },
    innerContainer: {
      height: '38vh',
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  'root::HomePage::LabResultsTable': {
    tableContainer: {
      borderWidth: 0,
      backgroundColor: '#F4F4F8',
      paddingLeft: 10,
      paddingRight: 10,
      height: '48vh',
      marginTop: 20,
    },
    innerContainer: {
      height: '38vh',
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  'root::HomePage::LabResultsTable::LabResultRow': {
    rowContainer: {
      marginLeft: 10,
      marginRight: 10,
      width: 'auto',
    },
    hover: {
      borderRadius: 8,
    },
  },
  'root::HomePage::MedicationTable::MedicationRow': {
    rowText: {
      color: '#748A9D',
    },
    rowContainer: {
      marginLeft: 10,
      marginRight: 10,
      width: 'auto',
    },
    hover: {
      borderRadius: 8,
    },
  },
  'root::MedicationPage::NothingSelected': {
    titleText: {
      color: '#6B1A2CBF',
    },
  },
  'root::LabResultsPage::NothingSelected': {
    titleText: {
      color: '#336699BE',
    },
  },
  'root::MedicationPage::MedicationDetails::InfoMessage': {
    infoIcon: {
      color: '#6B1A2CBF',
    },
  },
  'root::LabResultsPage::LabResultsPageDetails::PdfButton': {
    container: {
      backgroundColor: '#336699BE',
    },
  },
  'root::MedicationPage::PageList::PageGroup': {
    headerStyle: {
      color: '#1C4547',
    },
    iconStyle: {
      color: '#6B1A2CBF',
    },
  },
  'root::MedicationPage::MedicationDetails::MedicationPanels::MedicationPanel': {
    valueText: {
      color: '#6B1A2CBF',
    },
  },
  'root::MedicationPage::MedicationDetails::InfoTable::InfoRow': {
    descriptionText: {
      color: '#6B1A2CBF',
    },
  },
  'root::MedicationPage::MedicationDetails::MedicationDetailsTitle': {
    titleText: {
      color: '#1C4547',
    },
  },
  'root::InfoTable': {
    tableContainer: {
      marginTop: 20,
    },
  },
  'root::InfoTable::InfoRow': {
    descriptionText: {
      color: '#6B1A2CBF',
    },
  },
  'root::MedicationPopUpHeader': {
    headerIcon: {
      color: '#6B1A2CBF',
    },
    medicationText: {
      color: '#1C4547',
    },
  },
  'root::MedicationPanels::MedicationPanel': {
    valueText: {
      color: '#6B1A2CBF',
    },
    unitText: {
      color: '#6B1A2CBF',
    },
    descriptionText: {
      color: '#1C4547',
    },
  },
  'root::InfoMessage': {
    infoIcon: {
      color: '#6B1A2CBF',
    },
  },
  'root::MedicationPopUpHeader::PdfButton': {
    container: {
      backgroundColor: '#6B1A2CBF',
    },
  },
  'root::LabResultsPage::PageList::PageGroup': {
    headerStyle: {
      color: '#1C4547',
    },
    iconStyle: {
      color: '#336699BE',
    },
  },
  'root::LabResultPopUp::InfoTable': {
    tableContainer: {
      marginTop: 20,
    },
  },
  'root::LabResultPopUp::InfoTable::InfoRow': {
    descriptionText: {
      color: '#336699BE',
    },
  },
  'root::LabResultPopUp:LabResultPopUpHeader::PdfButton': {
    container: {
      backgroundColor: '#336699BE',
    },
  },
  'root::MobileMedicationDetailsPage::MobilePageHeader': {
    gradient: {
      colors: ['#B27677', '#6E1A2B'],
    },
  },
  'root::MobileMedicationDetailsPage::MobileMedicationDetailsHeader': {
    statusBox: {
      backgroundColor: '#6B1A2CBF',
      color: 'white',
    },
  },
  'root::MobileMedicationDetailsPage::MobileMedicationDetailsHeader::PrintButton': {
    container: {
      backgroundColor: '#6B1A2CBF',
    },
  },
  'root::MobileMedicationDetailsPage::MobileDetailsTitle': {
    icon: {
      color: '#6B1A2CBF',
    },
    titleText: {
      color: '#6B1A2CBF',
    },
    subTitleText: {
      color: '#1C4547',
    },
  },
  'root::MobileMedicationDetailsPage::MedicationPanels::MedicationPanel': {
    valueText: {
      color: '#6B1A2CBF',
    },
    unitText: {
      color: '#6B1A2CBF',
    },
    descriptionText: {
      color: '#1C4547',
    },
  },
  'root::MobileMedicationDetailsPage::InfoMessage': {
    infoIcon: {
      color: '#6B1A2CBF',
    },
    messageText: {
      color: '#1C4547',
    }
  },
  'root::MobileMedicationDetailsPage::InfoTable::InfoRow': {
    labelText: {
      color: '#1C4547',
    },
    rowIcon: {
      color: '#1C4547',
    },
    descriptionText: {
      color: '#6B1A2CBF',
    },
  },
  'root::MobileMedicationPage::MobilePageHeader': {
    gradient: {
      colors: ['#FFFFFF', '#FFFFFF'],
    },
    titleText: {
      color: '#1C1E47',
    },
    avatar: {
      color: '#1C4547',
    },
    avatarText: {
      color: '#FFFFFF',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
  },
  'root::MobileMedicationPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#1C4547',
    },
  },
  'root::MobileActiveMedicationPage::MobilePageHeader': {
    gradient: {
      colors: ['#FFFFFF', '#FFFFFF'],
    },
    titleText: {
      color: '#1C1E47',
    },
    avatar: {
      color: '#1C4547',
    },
    avatarText: {
      color: '#FFFFFF',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
  },
  'root::MobileActiveMedicationPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#1C4547',
    },
  },
  'root::MobileDispensedMedicationPage::MobilePageHeader': {
    gradient: {
      colors: ['#FFFFFF', '#FFFFFF'],
    },
    titleText: {
      color: '#1C1E47',
    },
    avatar: {
      color: '#1C4547',
    },
    avatarText: {
      color: '#FFFFFF',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
  },
  'root::MobileDispensedMedicationPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#1C4547',
    },
  },
  'root::MobilePrescribedMedicationPage::MobilePageHeader': {
    gradient: {
      colors: ['#FFFFFF', '#FFFFFF'],
    },
    titleText: {
      color: '#1C1E47',
    },
    avatar: {
      color: '#1C4547',
    },
    avatarText: {
      color: '#FFFFFF',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
  },
  'root::MobilePrescribedMedicationPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#1C4547',
    },
  },
  'root::MobileLabResultsPage::MobilePageHeader': {
    gradient: {
      colors: ['#006055', '#006055'],
    },
    titleText: {
      color: '#FFFFFF',
    },
    avatar: {
      color: '#FFFFFF',
    },
    avatarText: {
      color: '#79242F',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
    titleIcon: {
      height: 30,
      width: 30,
      color: '#FFFFFF',
    },
    iconName: 'lab-results',
  },
  'root::MobileLabResultsPage::MobileLabResultDetailsPage::MobilePageHeader': {
    gradient: {
      colors: ['#006055', '#006055'],
    },
    titleText: {
      color: '#FFFFFF',
    },
    avatar: {
      color: '#FFFFFF',
    },
    avatarText: {
      color: '#79242F',
    },
    hamburgerIcon: {
      color: '#1C4547',
    },
    hamburgerIconContainer: {
      borderColor: '#1C4547',
    },
    titleIcon: {
      height: 30,
      width: 30,
      color: '#FFFFFF',
    },
    iconName: 'lab-results',
  },
  'root::MobileLabResultsPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#FFFFFF',
    },
  },
  'root::MobileLabResultsPage::MobileLabResultDetailsPage::MobilePageHeader::MobileBackButton': {
    chevronLeftIcon: {
      color: '#FFFFFF',
    },
  },
  'root::HomePage::SideMenu::SideMenuHeader': {
    avatarText: {
      color: '#79242F',
    },
    colorGradient: {
      colors: ['#006055', '#006055'],
    },
  },
  'root::LabResultsPage::SideMenu::SideMenuHeader': {
    avatarText: {
      color: '#79242F',
    },
    colorGradient: {
      colors: ['#006055', '#006055'],
    },
  },
  'root::MedicationPage::SideMenu::SideMenuHeader': {
    avatarText: {
      color: '#79242F',
    },
    colorGradient: {
      colors: ['#006055', '#006055'],
    },
  },
  'root::MobileHomePage::MobileHeader': {
    avatarText: {
      color: '#79242F',
    },
    gradient: {
      colors: ['#006055', '#006055'],
    },
  },
  'root::MobileSideMenuHeader': {
    avatarText: {
      color: '#79242F',
    },
    linearGradient: {
      colors: ['#006055', '#006055'],
    },
  },
  'root::PageFallback': {
    container: {
      height: '100vh',
    },
    icon: {
      height: 'min(400px, 80vw)',
      width: 'min(400px, 80vw)',
    },
  },
};

export default style;