const networkConfig = {
  keycloakBaseUrl: 'https://iam.perftest.myhealth.gnb-doh.vsfcloud.com/auth/',
  keycloakTokenUrl: 'https://iam.perftest.myhealth.gnb-doh.vsfcloud.com/auth/realms/myhealth-broker/broker/gaas-dev-oidc/token',
  patientDataUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient/',
  medicationUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/medication/',
  immunizationUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/immunization/',
  observationUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  diagnosticReportUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  imagingUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  alertUrl: 'https://perftest.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/alert/',
  redirectUri: 'https://myhealth-web.perftest.myhealth.gnb-doh.vsfcloud.com/',
};

export default networkConfig;
