import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from '@verosource/myhealth-web/src/App';
import config from './config/';


ReactDOM.render(
  <BrowserRouter>
    <App incomingConfig={config} />
  </BrowserRouter>,
  document.getElementById('root')
);
