import app from './appConfig';
import components from './componentConfig';
import network from './networkConfig';
import styles from './style';

const config = {
  app, components, network, styles,
};

export default config;
