const componentConfig = {
  'root::HomePage': {
    disableMedicationTable: true,
    disableLabResultsTable: true,
  },
  'root::MobileHomePage': {
    disablePersonalCareLinks: true,
    disableServiceLinks: true,
    disableCurrentServices: true,
  },
  'root::MobileHomePage::HomePagePanels': {
    disableCurrentServices: true,
    disableMedicationPanel: true,
    disableImmunizationPanel: true,
    disableImagingPanel: true,
  },
  'root::HomePage::HomePagePanels': {
    disableCurrentServices: true,
    disableImmunizationPanel: true,
    disableMedicationPanel: true,
    disableImagingPanel: true,
  },
  'root::HomePage::SideMenu': {
    manageMyServices: false,
    disablePersonalCareLinks: true,
    isCollapsible: true,
  },
  'root::MedicationPage::SideMenu': {
    manageMyServices: false,
    disablePersonalCareLinks: true,
    isCollapsible: true,
  },
  'root::LabResultsPage::SideMenu': {
    manageMyServices: false,
    disablePersonalCareLinks: true,
    isCollapsible: true,
  },
  'root::HomePage::SideMenu::SideMenuOptions': {
    disableLanguageToggle: true,
    disableLogout: true,
  },
  'root::MobileSideMenuOptions': {
    disableLanguageToggle: true,
    disableLogout: true,
  },
  'root::LabResultsPage::SideMenu::SideMenuOptions': {
    disableLogout: true,
    disableLanguageToggle: true,
  },
  'root::HomePage::MedicationTable': {
    addMedication: true,
  },
  'root::MobileMedicationPage::MedicationTable': {
    addMedication: true,
  },
  'root::MobileActiveMedicationPage::MedicationTable': {
    addMedication: true,
  },
  'root::MobileDispensedMedicationPage::MedicationTable': {
    addMedication: true,
  },
  'root::MobilePrescribedMedicationPage::MedicationTable': {
    addMedication: true,
  },
  'root::HomePage::PageHeader': {
    headerLinks: true,
  },
  'root::MedicationPage::PageHeader': {
    headerLinks: true,
  },
  'root::LabResultsPage::PageHeader': {
    headerLinks: true,
  },
  'root::MobileSideMenu': {
    manageMyServices: false,
    disableProfileLinks: true,
    disableCurrentServices: true,
    services: [
      {
        title: 'pages.home.medications',
        icon: 'medication-pill',
        iconStyle: {
          color: '#367E9A',
          paddingRight: 10,
          height: 30,
          width: 30,
        },
        link: 'routing.medicationPage',
        showChevronRight: true,
        key: '0',
        disabled: true,
      },
      {
        title: 'pages.home.labResults',
        icon: 'lab-results',
        iconStyle: {
          color: '#4CA2D3',
          paddingRight: 10,
          height: 30,
          width: 30,
        },
        link: 'routing.labResultsPage',
        showChevronRight: true,
        key: '1',
      },
      {
        title: 'pages.home.immunization',
        icon: 'immunization',
        iconStyle: {
          color: '#506F9A',
          paddingRight: 10,
          height: 30,
          width: 30,
        },
        link: 'routing.immunizationPage',
        showChevronRight: true,
        key: '2',
        disabled: true,
      },
      {
        title: 'pages.home.imaging',
        icon: 'x-ray',
        iconStyle: {
          color: '#34ACAC',
          paddingRight: 10,
          height: 30,
          width: 30,
        },
        link: 'routing.imagingPage',
        showChevronRight: true,
        key: '3',
        disabled: true,
      },
    ],
  },
  'root::MobileLabResultsPage::MobilePageHeader': {
    mobilePageHeaderIcon: true,
  },
  'root::MobileLabResultsPage::MobileLabResultDetailsPage::MobilePageHeader': {
    mobilePageHeaderIcon: true,
  },
  'root::HomePage::SideMenu::ServicesLinks': {
    disableMedications: true
  },
  'root::LabResultsPage::SideMenu::ServicesLinks': {
    disableMedications: true
  },
  labResultsPage: {
    disableDisclaimer: false,
  },
};

export default componentConfig;